<template>
  <div>
    <Header />
    <br /><br /><br /><br /><br /><br /><br />
    <div class="container fluid">
      <div class="row" style="margin-top: 50px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/board_handling_system">
          <div class="card" style="width:260px;height:180px;">
          
            <img
              style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/mvis2.jpg"
              alt="Card image cap"
            />
            
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Bord handling system
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/screen_printer">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/screen.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Screen Printer
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/chip_mounter">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/chip.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Chip Mounter
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/reflow_oven">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/csm_Ersa-HOTFLOW-THREE-900x675-02_36ea151c27.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Reflow Oven
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
      <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/selective_soldering">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/selective.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Selective Soldering
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/wave_soldering">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/wave.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Wave Soldering
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/glue_potting">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/gluepo.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Glue potting Machine
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/glue_dispensing">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/gluepottin.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Glue Dispensing Machine
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a   @click="lasermarking()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:20%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/laser.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Laser Marking Machine 
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/routerdepann">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:40%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/router.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
               Router Depaneling
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="xray()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:80%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/xray.png"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                X Ray Machine
              </h4>
            </div>
          </div>
          </a>
        </div>
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <router-link to="/production_equipement/procleaning">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:70%;margin:auto;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/cleanng.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Cleaning Equipment
              </h4>
            </div>
          </div>
          </router-link>
        </div>
        <!-- end col -->
      </div>
       <div class="row" style="margin-top: 30px">
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="coating()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/coating.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Coating And Dispensing
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a  @click="humidity()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/humidity.png"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Humidity Management 
              </h4>
            </div>
          </div>
          </a>
        </div>
        <!-- end col -->
        <div class="col-md-6 col-xl-3">
          <!-- Simple card -->
          <a @click="laserselective()">
          <div class="card" style="width:260px;height:180px;">
            <img
            style="max-height:80%; max-width:100%;"
              class="card-img-top img-fluid"
              src="../../assets/images/Categories/Production_equipement/laserselctive.jpg"
              alt="Card image cap"
            />
            <div class="card-body">
              <h4 style="text-align: center" class="card-title">
                Laser Selective Soldering
              </h4>
            </div>
          </div>
          </a>
        </div>
       
        <!-- end col -->
      </div>
    </div>
    <Footer />
  </div>
</template>
<script>
import Header from "../../components/Header.vue";

import Footer from "../../components/Footer.vue";

/**
 * Dashboard component
 */
export default {
  components: {
    Header,

    Footer,
  },
  methods: {
     lasermarking(){
      window.location.href = "https://osai-as.com/en/neomark-easy";
    },
     xray(){
      window.location.href = "https://www.sakicorp.com/en/product/3daxi/";
    },
    coating(){
      window.location.href = "https://anda.com.hk/en/products/main";
    },
     humidity(){
      window.location.href = "https://dr-storageamerica.com/cabinets/x2m-cabinets/";
    },
     laserselective(){
      window.location.href = "https://www.seica.com/solutions/laser-selective-soldering/";
    },
  }
};
</script>